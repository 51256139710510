import { Controller } from 'stimulus'
//
// Todo; This controller requires jquery for the popover
// when we move to Bootstrap 5, this will need to be removed.
//
export default class extends Controller {
    static values = {
        url: String,
        title: String
    }

    static targets = [ "button" ]

    connect() {
        if(!this.urlValue) {
            console.log("popover controller requires a url-value")
        }

        if(!this.titleValue) {
            console.log("popover controller requires a title-value")
        }
    }

    pop() {
        $(".examtrack-popover").popover('hide')

        var target = this.buttonTarget;
        fetch(this.urlValue)
            .then(response => {
                if(response.status === 200) {
                    response.text().then( html => {
                        $("#" + target.id).popover({
                            animation: true,
                            placement: 'right',
                            template: '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header bg-primary text-white"></h3><div class="popover-body"></div></div>',
                            trigger: 'click focus',
                            html: true,
                            title: '<div class="d-flex justify-content-between"><div class="align-self-center">' + this.titleValue + '</div><a class="btn btn-sm btn-danger"><i class="fa fa-times-circle"> </i></a></div>',
                            content: html,
                        });

                        $("#" + target.id).popover('show');
                    })
                }
            })

    }
}
