const MAX_CONDITION_GROUP_DEPTH=3;

function et_convertSimpleFilterToAdvanced(id) {
    /*
        TODO: Don't delete the simple form, simply disable all of the fields, and then hide it.
        or alternativly undisable the fields and show the form.
     */

    /* Please use this as an exemplar to describe how the form should hang together.. */
    var filter_div = document.getElementById("filter_" + id);

    filter_div.innerHTML = "";

    var menu_data = filter_div.dataset.advancedmenudataid;
    if (menu_data === undefined) {
        menu_data = "{ }"
    } else {
        menu_data = $(menu_data).text();
    }
    var conditiongroup_add_button = "filter_" + id + "_conditiongroup_0_add_condition_button";

    var html = '<div class="filter-card">';
    html += '<div class="container-fluid">';
    html += '<div class="row">';
    html += '<div class="col-12">';
    html += '<div class="border bg-light pl-4 pr-4 p-2">';
    html += '<div class="d-flex justify-content-between pr-4">';
    html += '<div class="align-self-center">';
    html += '<i class="fa fa-filter"> </i> <strong>Advanced Search Filter</strong>';
    html += '</div>';
    html += '<div class="form-row">';
    html += '<div class="col-10">';
    // This select box needs to have a list of the users previously saved results....
    html += '<select id="saved-queries" class="d-none form-control">';
    html += '</select>';
    html += '</div>';
    html += '<div class="col-2">';
    // this button needs to replace this item, and build a new simple search form..
    html += '<button class="d-none"></button>';
    html += '<button type="button"  class="btn btn-outline-primary" onclick="et_buildSimpleFilter(null, '+ id +')"><i class="fa fa-chevron-up"> </i></button>';
    html += '</div>';
    html += '</div>';
    html += '</div>';
    html += '</div>';

    html += '<div class="border-bottom border-left border-right p-4">';
    html += '<div class="d-flex justify-content-between">';
    html += '<div class="btn-group btn-group-toggle" data-toggle="buttons">';
    html += '<label id="filter_'+ id + '_top-level-and-button" class="d-none btn btn-outline-secondary form-control-no-border active">';
    html += '<input type="radio" name="q[m]" value="and" id="q[g][m][and]" autocomplete="off" checked > AND';
    html += '</label>';
    html += '<label id="filter_'+ id + '_top-level-or-button" class="d-none btn btn-outline-secondary form-control-no-border">';
    html += '<input type="radio" name="q[m]" value="or" id="q[g][m][or]" autocomplete="off" > OR';
    html += '</label>';
    html += '</div>';
    html += '<div class="text-right">';
    html += '<button class="btn btn-outline-success" onclick="buildConditionSet(event, \'filter_' + id + '_conditiongroup_0\');"><strong>{ }</strong></button>';
    html += '</div>';
    html += '</div>';
    html += '<div id="filter_' + id + '_conditiongroup_0" data-conditiongroups="1" data-form-prefix="" data-filter-id="'+ id +'">';
    // this is the first box containing the possible conditions.
    html += '<div class="border bg-light m-3 p-3">';
    html += '<div class="d-flex justify-content-between">';
    // this is the context button to add a new condition.
    html += '<div>';
    html += '<button class="filter_' + id + '_condition-add-button btn btn-outline-success" id="'+ conditiongroup_add_button +'"><i class="fa fa-plus"></i></button>';


    html += '<div class="pl-2 btn-group btn-group-toggle" data-toggle="buttons">';
    html += '<label id="filter_' + id + '_conditiongroup_0-and-button" class="d-none btn btn-outline-secondary form-control-no-border active">';
    html += '<input type="radio" name="q[g][0][m]" value="and" id="q[g][0][m][and]" autocomplete="off" checked> AND';
    html += '</label>';
    html += '<label id="filter_' + id + '_conditiongroup_0-or-button" class="d-none btn btn-outline-secondary form-control-no-border">';
    html += '<input type="radio" name="q[g][0][m]" value="or" id="q[g][0][m][or]" autocomplete="off"> OR';
    html += '</label>';
    html += '</div>';
    html += '</div>';
    html += '<div class="text-right">';
    html += '<button class="btn btn-outline-success" onclick="buildConditionSet(event, \'filter_' + id + '_conditiongroup_0_conditions\');"><strong>{ }</strong></button>';
    html += '</div>';

    html += '</div>';
    html += '<div id="filter_' + id + '_conditiongroup_0_conditions" data-form-prefix="[g][0]" data-conditions="0" data-conditiongroups="0" data-parent-conditiongroup="filter_' + id + '_conditiongroup_0" data-filter-id="'+ id +'" class="mt-2">';
    html += '</div>';
    html += '<div id="filter_' + id + '_conditiongroup_0_conditiongroups" data-form-prefix="[g][0]" class="mt-2">';
    html += '</div>';

    html += '</div>';

    html += '</div>';
    html += '</div>';
    html += '<div class="border-bottom border-left border-right p-2">';
    html += '<div class="text-right">';
    html += '<div class="btn-group align-self-end" role="group" aria-label="Filter Submission">';
    html += '<button class="btn btn-outline-success" id="filter_' + id + '_submit"><i class="fa fa-search"> </i> Apply Filters</button>';
    html += '<button class="btn btn-outline-danger" onclick="et_convertSimpleFilterToAdvanced(' +id +');"><i class="fa fa-times"> </i> Clear Filters</button>';
    html += '</div>';
    html += '</div>';
    html += '</div>';
    html += '</div>';
    html += '</div>';
    html += '</div>';
    html += '</div>';
    filter_div.innerHTML = html;

    context.attach(".filter_" + id + "_condition-add-button", JSON.parse(menu_data));
    filter_div.dataset.totalConditionGroups = "1"
    filter_div.dataset.totalConditions = "0"
}

function et_buildSimpleFilter(filter_div, index) {
    /*
        TODO: of the filter already exists, simply disable all the elements and hide it/ enable all of the elements and
        unhide it....
     */
    if(filter_div === null) {
        filter_div = document.getElementById("filter_" + index);
    }
    filter_div.setAttribute("id", "filter_" + index)
    var simpleFilterField = filter_div.dataset.simplefilterfield === undefined ? "name_cont" : filter_div.dataset.simplefilterfield;
    var simplePlaceholder = filter_div.dataset.simplefilterplaceholder === undefined ? "Search..." : filter_div.dataset.simplefilterplaceholder
    var includeID = filter_div.dataset.simplefilterincludeid === undefined ? "false" : filter_div.dataset.simplefilterincludeid
    var includeAdvanced = filter_div.dataset.simplefilterincludeadvanced === undefined ? "false" : filter_div.dataset.simplefilterincludeadvanced
    // clear the filter contents.
    filter_div.innerHTML = "";
    var filter_card_div = document.createElement("div");
    filter_card_div.className = "filter-card";
    filter_card_div.id="filter_card_" + index;

    var container_div = document.createElement("div");
    container_div.className="container-fluid";

    var row_0_div = document.createElement("div");
    row_0_div.className = "row";

    var row_0_col_0_div = document.createElement("div");
    row_0_col_0_div.className = "col-12";

    var input_group_div = document.createElement("div");
    input_group_div.className = "input-group";

    if(includeID === "true") {
        var id_input = document.createElement("input");
        id_input.setAttribute("placeholder", "ID...");
        id_input.setAttribute("name", "q[id_eq]");
        id_input.className="form-control col-1 form-control-no-border";
        id_input.id = "filter_id_" + index;
        input_group_div.appendChild(id_input);
    }

    var text_input_div = document.createElement("input");
    text_input_div.setAttribute("placeholder", simplePlaceholder);
    text_input_div.setAttribute("name", "q[" + simpleFilterField+"]");
    text_input_div.className="form-control form-control-no-border";
    text_input_div.id = "filter_" + index + "_simplefield_";

    var input_group_append_div = document.createElement("div");
    input_group_append_div.className = "input-group-append";

    var btn_submit = document.createElement("button");
    btn_submit.className="btn btn-outline-success form-control-no-border"
    btn_submit.setAttribute("name", "button");
  //  btn_submit.setAttribute("type", "button");
    btn_submit.innerHTML = "<i class=\"fa fa-search\"> </i>"

    input_group_div.appendChild(text_input_div);
    input_group_append_div.appendChild(btn_submit)

    // You could make this a configurable part of the setup by adding an if statement here..
    if (includeAdvanced === "true") {
        var btn_adv_search = document.createElement("button");
        btn_adv_search.className = "btn btn-outline-primary filter-collapse-button form-control-no-border"
        btn_adv_search.setAttribute("name", "btn_adv_search");
        btn_adv_search.setAttribute("type", "button");
        btn_adv_search.setAttribute("onclick", "et_convertSimpleFilterToAdvanced(" + index + ")");
        btn_adv_search.innerHTML = "<i class=\"fa fa-filter\"> </i>"
        input_group_append_div.appendChild(btn_adv_search)
    }

    input_group_div.appendChild(input_group_append_div);
    row_0_col_0_div.appendChild(input_group_div);
    row_0_div.appendChild(row_0_col_0_div);
    container_div.appendChild(row_0_div);
    filter_card_div.appendChild(container_div);

    filter_div.appendChild(filter_card_div);
}

function create_condition(event, selector, parameters) {
    event.preventDefault();
    console.log(selector)
    var split_selector_id_data = selector[0].id.split("_");
    var filter_id = split_selector_id_data[1];
    var condition_group_id = split_selector_id_data[3];
    var filter_div = document.getElementById("filter_" + filter_id);
    var condition_group_div = document.getElementById("filter_"+ filter_id + "_conditiongroup_" + condition_group_id +"_conditions");
    var total_conditions_in_filter = parseInt(filter_div.dataset.totalConditions);
    var total_conditions_in_group = parseInt(condition_group_div.dataset.conditions);
    var form_field_prefix = condition_group_div.dataset.formPrefix + "[c][" + condition_group_div.dataset.conditions + "]";

    if(condition_group_div.dataset.conditions > 0 || condition_group_div.dataset.conditiongroups > 0) {
        var toggle_name = condition_group_div.id.split("_")[0] + "_" + condition_group_div.id.split("_")[1] + "_" + condition_group_div.id.split("_")[2] + "_" + condition_group_div.id.split("_")[3];
        document.getElementById(toggle_name + '-and-button').classList.remove("d-none");
        document.getElementById(toggle_name + '-or-button').classList.remove("d-none");
    }

    var condition_div = document.createElement("div");
    condition_div.className = "ml-3";
    condition_div.id = "filter_" + filter_id + "_conditiongroup_" + condition_group_id + "_condition_" + total_conditions_in_group;

    var condition_row_div = document.createElement("div");
    condition_row_div.className = "form-row";
    condition_row_div.id = "fr_" + Date.now();

    var form_group_div = document.createElement("div");
    form_group_div.className = "form-group d-flex align-self-center text-center col-md-4";

    var delete_button = document.createElement("button");
    delete_button.className = "btn btn-danger btn-xs";
    delete_button.setAttribute("onclick", "remove_condition(event," + filter_id +"," + condition_group_id + "," + total_conditions_in_group + ");");
    delete_button.innerHTML = "<i class=\"fa fa-times\"> </i>";

    var name_of_field = document.createElement("span");
    name_of_field.className = "align-middle pl-4";
    name_of_field.innerHTML = "<strong>" + parameters.friendlyName + "</strong>";


    form_group_div.appendChild(delete_button);
    form_group_div.appendChild(name_of_field);
    condition_row_div.appendChild(form_group_div)
    switch(parameters.fieldType) {
        case "text":
            condition_row_div = add_filter_text_field(parameters, form_field_prefix, condition_row_div);
            break;
        case "date":
            condition_row_div = add_filter_date_field(parameters, form_field_prefix, condition_row_div);
            break;
        case "number":
            condition_row_div = add_filter_number_field(parameters, form_field_prefix, condition_row_div);
            break;
        case "decimal":
            condition_row_div = add_filter_decimal_field(parameters, form_field_prefix, condition_row_div);
            break;
        case "association":
            condition_row_div = add_filter_association_field(parameters,  form_field_prefix, condition_row_div);
            break;
        case "exact":
            condition_row_div = add_filter_exact_field(parameters, form_field_prefix, condition_row_div);
            break;
        case "null":
            condition_row_div = add_filter_null_field(parameters, form_field_prefix, condition_row_div);
            break;
        case "noteq":
            condition_row_div = add_filter_noteq_field(parameters, form_field_prefix, condition_row_div);
            break;
        case "one_exact_one_number":
            form_field_prefix = condition_group_div.dataset.formPrefix + "[g][" +   condition_group_div.dataset.conditiongroups + "]";
            condition_row_div = add_one_exact_one_number(parameters, form_field_prefix, condition_row_div);
            // We have to add an extra one here, since the state field adds an extra field.
            condition_group_div.dataset.conditiongroups = parseInt(condition_group_div.dataset.conditiongroups) + 1;
            break;
        case "state":
            form_field_prefix = condition_group_div.dataset.formPrefix + "[g][" +   condition_group_div.dataset.conditiongroups + "]";
            condition_row_div = add_filter_state_field(parameters, form_field_prefix, condition_row_div);
            // We have to add an extra one here, since the state field adds an extra field.
            condition_group_div.dataset.conditiongroups = parseInt(condition_group_div.dataset.conditiongroups) + 1;
            //total_conditions_in_filter = total_conditions_in_filter -1;
            //total_conditions_in_group = total_conditions_in_group -1;
            break;
    }
    condition_div.appendChild(condition_row_div)
    condition_group_div.appendChild(condition_div);

    filter_div.dataset.totalConditions = total_conditions_in_filter + 1;
    condition_group_div.dataset.conditions = total_conditions_in_group +1;

    var pickers = multiPickers("[id=" + condition_row_div.id + "] > div > input.date-picker", {
        startDay: 1,
        formatter: (input, date, instance) => {
            var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
            var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
            var yyyy = date.getFullYear();
            // This will display the date as `01/01/2019`.
            input.value = dd + '/' + MM + '/' + yyyy
        },
    });

    $('input[type=text]').on('keydown', function(e) {
        if (e.which == 13) {
            e.preventDefault();
            $("#filter_" + filter_id + "_submit").click();
        }
    });
}

function remove_condition(event, filter_id, condition_group_id, condition_id) {
    event.preventDefault();
    var filter_div = document.getElementById("filter_"+ filter_id);
    var condition_div = document.getElementById("filter_" + filter_id + "_conditiongroup_" + condition_group_id +"_condition_" + condition_id);
    var condition_group_div = document.getElementById("filter_" + filter_id + "_conditiongroup_" + condition_group_id + "_conditions")
    var total_conditions_in_filter = parseInt(filter_div.dataset.totalConditions);
    var total_conditions_in_condition_group = parseInt(condition_group_div.dataset.conditions);

    condition_div.remove();
    filter_div.dataset.totalConditions = total_conditions_in_filter -1;
    condition_group_div.dataset.conditions = total_conditions_in_condition_group -1;
    if((parseInt(condition_group_div.dataset.conditions) + parseInt(condition_group_div.dataset.conditiongroups) < 2)) {
        var toggle_name = condition_group_div.id.split("_")[0] + "_" + condition_group_div.id.split("_")[1] + "_" + condition_group_div.id.split("_")[2] + "_" + condition_group_div.id.split("_")[3];
        document.getElementById(toggle_name + '-and-button').classList.add("d-none");
        document.getElementById(toggle_name + '-or-button').classList.add("d-none");
    }

}

function buildConditionSet(event, conditiongroup_conditions_id) {
    event.preventDefault();
    // todo:  This entire method needs refactoring.  It's difficult to read. Even move difficult to piece together
    var conditiongroup_conditions_div = document.getElementById(conditiongroup_conditions_id);
    var current_depth = et_filter_calculate_depth(conditiongroup_conditions_div);

    if (current_depth >= MAX_CONDITION_GROUP_DEPTH) {
        return;
    }

    var parent_conditiongroup_div = document.getElementById(conditiongroup_conditions_div.dataset.parentConditiongroup);
    var filter_div = document.getElementById("filter_" + conditiongroup_conditions_div.dataset.filterId)
    var menu_data = filter_div.dataset.advancedmenudataid;
    var conditiongroup_add_button_id_string = "filter_" + conditiongroup_conditions_div.dataset.filterId + "_conditiongroup_" + filter_div.dataset.totalConditionGroups + "_add_button";

    // since an outer div group doesn't have a parent, lets make it ourself
    if(parent_conditiongroup_div === null) {
        parent_conditiongroup_div = conditiongroup_conditions_div;
    }

    var form_prefix = conditiongroup_conditions_div.dataset.formPrefix + '[g][' + (conditiongroup_conditions_div.dataset.conditiongroups) +']';

    if (menu_data === undefined) {
        menu_data = "{ }"
    } else {
        menu_data = $(menu_data).text();
    }

    // we do slightly different things depending on whether we're the innie or the outie...
    if (conditiongroup_conditions_id.indexOf("conditions") > 0) {
        // if this is an inner group of conditions
        // get the element
        var conditiongroup_div = document.getElementById(conditiongroup_conditions_id.split("_")[0] + "_" + conditiongroup_conditions_id.split("_")[1] + "_" + conditiongroup_conditions_id.split("_")[2] + "_" + conditiongroup_conditions_id.split("_")[3] + "_conditiongroups");

        // switch on the toggle selectors if applicable
        if (parseInt(conditiongroup_conditions_div.dataset.conditiongroups) > 0 || parseInt(conditiongroup_conditions_div.dataset.conditions) > 0) {
            var toggle_name = conditiongroup_conditions_id.split("_")[0] + "_" + conditiongroup_conditions_id.split("_")[1] + "_" + conditiongroup_conditions_id.split("_")[2] + "_" + conditiongroup_conditions_id.split("_")[3];
            document.getElementById(toggle_name + '-and-button').classList.remove("d-none");
            document.getElementById(toggle_name + '-or-button').classList.remove("d-none");
        }
    } else {
        // otherwise we are the outer group
        // get the element
        var conditiongroup_div = document.getElementById(conditiongroup_conditions_id);
        var toggle_name = "filter_" + conditiongroup_div.dataset.filterId + "_top-level";
        document.getElementById(toggle_name + '-and-button').classList.remove("d-none");
        document.getElementById(toggle_name + '-or-button').classList.remove("d-none");
    }

    // Build each of the elements.
    var entire_conditiongroup_div = document.createElement("div");
    entire_conditiongroup_div.setAttribute("data-parent-conditiongroup",conditiongroup_conditions_div.id)
    entire_conditiongroup_div.id = "filter_" + conditiongroup_conditions_div.dataset.filterId + "_entire_conditiongroup_" + filter_div.dataset.totalConditionGroups;
    entire_conditiongroup_div.className = "border bg-light m-3 p-3";

    var entire_conditiongroup_div_flex_div = document.createElement("div");
    entire_conditiongroup_div_flex_div.className = "d-flex justify-content-between";

    var entire_conditiongroup_div_flex_extra_div = document.createElement("div");

    var condition_add_btn = document.createElement("button");
    condition_add_btn.className = "filter_" + conditiongroup_conditions_div.dataset.filterId + "_condition-add-button btn btn-outline-success";
    condition_add_btn.id = conditiongroup_add_button_id_string;
    condition_add_btn.innerHTML = "<i class=\"fa fa-plus\"></i>";

    // Configure the toggle buttons.  AND/OR
    var condition_toggle_btns = document.createElement("div");
    condition_toggle_btns.className = "pl-2 btn-group btn-group-toggle";
    condition_toggle_btns.setAttribute("data-toggle", "buttons");

    var and_condition_label = document.createElement("label");
    and_condition_label.id = "filter_" + conditiongroup_conditions_div.dataset.filterId + "_conditiongroup_" + filter_div.dataset.totalConditionGroups + "-and-button";
    and_condition_label.className = "d-none btn btn-outline-secondary form-control-no-border active";

    var and_condition = document.createElement("input");
    and_condition.setAttribute("type", "radio");
    and_condition.setAttribute("name","q" + form_prefix +"[m]");
    and_condition.setAttribute("value", "and");
    and_condition.setAttribute("checked", "on");
    and_condition.setAttribute("autocomplete", "off");
    and_condition.id = form_prefix + "[m][and]";

    var or_condition_label = document.createElement("label");
    or_condition_label.id = "filter_" + conditiongroup_conditions_div.dataset.filterId + "_conditiongroup_" + filter_div.dataset.totalConditionGroups + "-or-button";
    or_condition_label.className = "d-none btn btn-outline-secondary form-control-no-border";

    var or_condition = document.createElement("input");
    or_condition.setAttribute("type", "radio");
    or_condition.setAttribute("name","q" + form_prefix +"[m]");
    or_condition.setAttribute("value", "or");
    or_condition.setAttribute("autocomplete", "off");
    or_condition.id = form_prefix + "[m][or]";

    and_condition_label.appendChild(and_condition);
    and_condition_label.innerHTML += " AND"
    or_condition_label.appendChild(or_condition);
    or_condition_label.innerHTML += " OR"
    condition_toggle_btns.appendChild(and_condition_label);
    condition_toggle_btns.appendChild(or_condition_label);
    // END Toggle Buttons.

    //
    // BUILD RIGHT HAND SIDE BUTTONS
    //
    var right_hand_side_buttons_div = document.createElement("div");
    right_hand_side_buttons_div.className = "text-right";

    // Only show the new condition group button when we hit max depth.,
    if (current_depth < MAX_CONDITION_GROUP_DEPTH - 1) {
        var build_conditionset_btn = document.createElement("button");
        build_conditionset_btn.className = "btn btn-outline-success";
        build_conditionset_btn.innerHTML = "<strong>{ }</strong>";
        build_conditionset_btn.setAttribute("onclick", 'buildConditionSet(event, "filter_' + conditiongroup_conditions_div.dataset.filterId + '_conditiongroup_' + filter_div.dataset.totalConditionGroups + '_conditions' + '")');
        right_hand_side_buttons_div.appendChild(build_conditionset_btn);
    }

    var delete_conditionset_btn = document.createElement("button");
    delete_conditionset_btn.className = "btn btn-outline-danger ml-1";
    delete_conditionset_btn.setAttribute("onclick", 'deleteConditionSet(event, "filter_' + conditiongroup_conditions_div.dataset.filterId + '_entire_conditiongroup_' + filter_div.dataset.totalConditionGroups + '")');
    delete_conditionset_btn.innerHTML = "<strong><i class=\"fa fa-times\"> </i></strong>";

    right_hand_side_buttons_div.appendChild(delete_conditionset_btn);
    //
    // END RIGHT HAND SIDE BUTTONS
    //

    entire_conditiongroup_div_flex_extra_div.appendChild(condition_add_btn);
    entire_conditiongroup_div_flex_extra_div.appendChild(condition_toggle_btns);
    entire_conditiongroup_div_flex_div.appendChild(entire_conditiongroup_div_flex_extra_div)
    entire_conditiongroup_div_flex_div.appendChild(right_hand_side_buttons_div)
    entire_conditiongroup_div.appendChild(entire_conditiongroup_div_flex_div)

    // This holds all of the conditions.
    var condition_container_div = document.createElement("div");
    condition_container_div.id = "filter_" +  conditiongroup_conditions_div.dataset.filterId + "_conditiongroup_" + filter_div.dataset.totalConditionGroups + "_conditions";
    condition_container_div.setAttribute("data-conditions","0")
    condition_container_div.setAttribute("data-conditiongroups","0")
    condition_container_div.setAttribute("data-parent-conditiongroup", conditiongroup_conditions_div.id);
    condition_container_div.setAttribute("data-form-prefix", form_prefix );
    condition_container_div.setAttribute("data-filter-id",  conditiongroup_conditions_div.dataset.filterId);
    condition_container_div.className = "mt-2";

    // this will hold further condition groups.
    var conditiongroup_container_div = document.createElement("div");
    conditiongroup_container_div.id = "filter_" +  conditiongroup_conditions_div.dataset.filterId + "_conditiongroup_" + filter_div.dataset.totalConditionGroups + "_conditiongroups";
    conditiongroup_container_div.setAttribute("data-form-prefix", form_prefix);
    conditiongroup_container_div.className = "mt-2"

    // finally add the conditongroups and the conditions containers
    entire_conditiongroup_div.appendChild(condition_container_div);
    entire_conditiongroup_div.appendChild(conditiongroup_container_div);
    conditiongroup_div.appendChild(entire_conditiongroup_div);

    // Attach a menu to the add button.
    context.attach(".filter_" +  conditiongroup_conditions_div.dataset.filterId +"_condition-add-button", JSON.parse(menu_data));

    // Housekeeping.  increment the condition groups and conditions.
    conditiongroup_conditions_div.dataset.conditiongroups = parseInt(conditiongroup_conditions_div.dataset.conditiongroups) +1;
    filter_div.dataset.totalConditionGroups = parseInt(filter_div.dataset.totalConditionGroups) +1;
}

function deleteConditionSet(event, entire_conditiongroup_id) {
    event.preventDefault();
    var entire_conditiongroup_div = document.getElementById(entire_conditiongroup_id);
    var parent_conditiongroup_div = document.getElementById(entire_conditiongroup_div.dataset.parentConditiongroup);
    parent_conditiongroup_div.dataset.conditiongroups = parseInt(parent_conditiongroup_div.dataset.conditiongroups) -1;
    if((parseInt(parent_conditiongroup_div.dataset.conditiongroups) + parseInt(parent_conditiongroup_div.dataset.conditions) <= 1)) {
        var toggle_name = parent_conditiongroup_div.id.split("_")[0] + "_" + parent_conditiongroup_div.id.split("_")[1] + "_" + parent_conditiongroup_div.id.split("_")[2] + "_" + parent_conditiongroup_div.id.split("_")[3];
        document.getElementById(toggle_name + '-and-button').classList.add("d-none");
        document.getElementById(toggle_name + '-or-button').classList.add("d-none");
    }
    entire_conditiongroup_div.outerHTML = "";
}

function et_filter_calculate_depth(conditiongroup_conditions_div) {
    if(conditiongroup_conditions_div.id === "filter_" + conditiongroup_conditions_div.dataset.filterId + "_conditiongroup_0") {
        return 0;
    } else {
        var current_depth = 0;
        var parent_id = conditiongroup_conditions_div.dataset.parentConditiongroup;
        while(parent_id != "filter_" + conditiongroup_conditions_div.dataset.filterId + "_conditiongroup_0") {
            current_depth += 1;
            parent = document.getElementById(parent_id)
            parent_id = parent.dataset.parentConditiongroup;

        }
        return current_depth;
    }
}

function add_filter_text_field(parameters, form_field_prefix = null, outer_div) {
    var form_grp_0 = document.createElement("div");
    form_grp_0.className = "form-group col-md-3";

    var select = document.createElement("select");
    select.setAttribute("name", "q" + form_field_prefix + "[p]");
    select.className = "form-control";

    var option_contains = document.createElement("option");
    option_contains.setAttribute("value", "cont");
    option_contains.innerText = "Contains";

    var option_does_not_contain = document.createElement("option");
    option_does_not_contain.setAttribute("value", "not_cont");
    option_does_not_contain.innerText = "Does not contain";

    select.appendChild(option_contains);
    select.appendChild(option_does_not_contain);

    form_grp_0.appendChild(select);

    var form_grp_1 = document.createElement("div");
    form_grp_1.className = "form-group col-md-5";

    var input = document.createElement("input");
    input.setAttribute("name", "q" + form_field_prefix + "[v][0][value]");
    input.setAttribute("type", "text");
    input.id = form_field_prefix + "[v][0][value]";
    input.className = "form-control";

    var hidden = document.createElement("input");
    hidden.setAttribute("name", "q" + form_field_prefix + "[a][0][name]");
    hidden.setAttribute("value", parameters.fieldName);
    hidden.setAttribute("type", "hidden");
    hidden.id = form_field_prefix + "[v][0][value]";

    var meta_type = document.createElement("input");
    meta_type.setAttribute("name", "q" + form_field_prefix + "[et_meta][type]");
    meta_type.setAttribute("value", "text");
    meta_type.setAttribute("type", "hidden");
    meta_type.id = form_field_prefix + "[et_meta][type]";

    var meta_friendlyname = document.createElement("input");
    meta_friendlyname.setAttribute("name", "q" + form_field_prefix + "[et_meta][friendly_name]");
    meta_friendlyname.setAttribute("value", parameters.friendlyName);
    meta_friendlyname.setAttribute("type", "hidden");
    meta_friendlyname.id = form_field_prefix + "[et_meta][friendly_name]";

    form_grp_1.appendChild(meta_friendlyname);
    form_grp_1.appendChild(meta_type);
    form_grp_1.appendChild(hidden);
    form_grp_1.appendChild(input);

    outer_div.appendChild(form_grp_0);
    outer_div.appendChild(form_grp_1);

    return outer_div;
}

function add_filter_number_field(parameters, form_field_prefix = null, outer_div) {
    var form_grp_0 = document.createElement("div");
    form_grp_0.className = "form-group col-md-3";

    var select = document.createElement("select");
    select.setAttribute("name", "q" + form_field_prefix + "[p]");
    select.className = "form-control";

    var option_eq = document.createElement("option");
    option_eq.setAttribute("value", "eq");
    option_eq.innerText = "Is";

    var option_not_eq = document.createElement("option");
    option_not_eq.setAttribute("value", "not_eq");
    option_not_eq.innerText = "Is Not";

    var option_gt = document.createElement("option");
    option_gt.setAttribute("value", "gt");
    option_gt.innerText = "Is Greater Than";

    var option_lt = document.createElement("option");
    option_lt.setAttribute("value", "lt");
    option_lt.innerText = "Is Less Than";

    var option_does_not_contain = document.createElement("option");
    option_does_not_contain.setAttribute("value", "not_cont");
    option_does_not_contain.innerText = "Does not contain";

    select.appendChild(option_eq);
    select.appendChild(option_not_eq);
    select.appendChild(option_gt);
    select.appendChild(option_lt);

    form_grp_0.appendChild(select);

    var form_grp_1 = document.createElement("div");
    form_grp_1.className = "form-group col-md-5";

    var input = document.createElement("input");
    input.setAttribute("name", "q" + form_field_prefix + "[v][0][value]");
    input.setAttribute("type", "number");
    input.setAttribute("step", "1");
    input.id = form_field_prefix + "[v][0][value]";
    input.className = "form-control";

    var hidden = document.createElement("input");
    hidden.setAttribute("name", "q" + form_field_prefix + "[a][0][name]");
    hidden.setAttribute("value", parameters.fieldName);
    hidden.setAttribute("type", "hidden");
    hidden.id = form_field_prefix + "[v][0][value]";

    var meta_type = document.createElement("input");
    meta_type.setAttribute("name", "q" + form_field_prefix + "[et_meta][type]");
    meta_type.setAttribute("value", "number");
    meta_type.setAttribute("type", "hidden");
    meta_type.id = form_field_prefix + "[et_meta][type]";

    var meta_friendlyname = document.createElement("input");
    meta_friendlyname.setAttribute("name", "q" + form_field_prefix + "[et_meta][friendly_name]");
    meta_friendlyname.setAttribute("value", parameters.friendlyName);
    meta_friendlyname.setAttribute("type", "hidden");
    meta_friendlyname.id = form_field_prefix + "[et_meta][friendly_name]";

    form_grp_1.appendChild(meta_friendlyname);
    form_grp_1.appendChild(meta_type);
    form_grp_1.appendChild(hidden);
    form_grp_1.appendChild(input);

    outer_div.appendChild(form_grp_0);
    outer_div.appendChild(form_grp_1);

    return outer_div;
}

function add_filter_decimal_field(parameters, form_field_prefix = null, outer_div) {
    var form_grp_0 = document.createElement("div");
    form_grp_0.className = "form-group col-md-3";

    var select = document.createElement("select");
    select.setAttribute("name", "q" + form_field_prefix + "[p]");
    select.className = "form-control";

    var option_eq = document.createElement("option");
    option_eq.setAttribute("value", "eq");
    option_eq.innerText = "Is";

    var option_not_eq = document.createElement("option");
    option_not_eq.setAttribute("value", "not_eq");
    option_not_eq.innerText = "Is Not";

    var option_gt = document.createElement("option");
    option_gt.setAttribute("value", "gt");
    option_gt.innerText = "Is Greater Than";

    var option_lt = document.createElement("option");
    option_lt.setAttribute("value", "lt");
    option_lt.innerText = "Is Less Than";

    var option_does_not_contain = document.createElement("option");
    option_does_not_contain.setAttribute("value", "not_cont");
    option_does_not_contain.innerText = "Does not contain";

    select.appendChild(option_eq);
    select.appendChild(option_not_eq);
    select.appendChild(option_gt);
    select.appendChild(option_lt);

    form_grp_0.appendChild(select);

    var form_grp_1 = document.createElement("div");
    form_grp_1.className = "form-group col-md-5";

    var input = document.createElement("input");
    input.setAttribute("name", "q" + form_field_prefix + "[v][0][value]");
    input.setAttribute("type", "number");
    input.setAttribute("step", ".01");
    input.id = form_field_prefix + "[v][0][value]";
    input.className = "form-control";

    var hidden = document.createElement("input");
    hidden.setAttribute("name", "q" + form_field_prefix + "[a][0][name]");
    hidden.setAttribute("value", parameters.fieldName);
    hidden.setAttribute("type", "hidden");
    hidden.id = form_field_prefix + "[v][0][value]";

    var meta_type = document.createElement("input");
    meta_type.setAttribute("name", "q" + form_field_prefix + "[et_meta][type]");
    meta_type.setAttribute("value", "decimal");
    meta_type.setAttribute("type", "hidden");
    meta_type.id = form_field_prefix + "[et_meta][type]";

    var meta_friendlyname = document.createElement("input");
    meta_friendlyname.setAttribute("name", "q" + form_field_prefix + "[et_meta][friendly_name]");
    meta_friendlyname.setAttribute("value", parameters.friendlyName);
    meta_friendlyname.setAttribute("type", "hidden");
    meta_friendlyname.id = form_field_prefix + "[et_meta][friendly_name]";

    form_grp_1.appendChild(meta_friendlyname);
    form_grp_1.appendChild(meta_type);

    form_grp_1.appendChild(hidden);
    form_grp_1.appendChild(input);

    outer_div.appendChild(form_grp_0);
    outer_div.appendChild(form_grp_1);

    return outer_div;
}

function add_filter_date_field(parameters, form_field_prefix = null, outer_div) {
    var form_grp_0 = document.createElement("div");
    form_grp_0.className = "form-group col-md-3";

    var select = document.createElement("select");
    select.setAttribute("name", "q" + form_field_prefix + "[p]");
    select.className = "form-control";

    var option_eq = document.createElement("option");
    option_eq.setAttribute("value", "eq");
    option_eq.innerText = "Is";

    var option_not_eq = document.createElement("option");
    option_not_eq.setAttribute("value", "not_eq");
    option_not_eq.innerText = "Is Not";

    var option_gt = document.createElement("option");
    option_gt.setAttribute("value", "gt");
    option_gt.innerText = "Is Greater Than";

    var option_lt = document.createElement("option");
    option_lt.setAttribute("value", "lt");
    option_lt.innerText = "Is Less Than";

    var option_does_not_contain = document.createElement("option");
    option_does_not_contain.setAttribute("value", "not_cont");
    option_does_not_contain.innerText = "Does not contain";

    select.appendChild(option_eq);
    select.appendChild(option_not_eq);
    select.appendChild(option_gt);
    select.appendChild(option_lt);

    form_grp_0.appendChild(select);

    var form_grp_1 = document.createElement("div");
    form_grp_1.className = "form-group col-md-5";

    var input = document.createElement("input");
    input.setAttribute("name", "q" + form_field_prefix + "[v][0][value]");
    input.setAttribute("type", "text");
    input.setAttribute("readonly", "true");
    input.id = form_field_prefix + "[v][0][value]";
    input.className = "form-control date-picker";

    var hidden = document.createElement("input");
    hidden.setAttribute("name", "q" + form_field_prefix + "[a][0][name]");
    hidden.setAttribute("value", parameters.fieldName);
    hidden.setAttribute("type", "hidden");
    hidden.id = form_field_prefix + "[v][0][value]";

    var meta_type = document.createElement("input");
    meta_type.setAttribute("name", "q" + form_field_prefix + "[et_meta][type]");
    meta_type.setAttribute("value", "date");
    meta_type.setAttribute("type", "hidden");
    meta_type.id = form_field_prefix + "[et_meta][type]";

    var meta_friendlyname = document.createElement("input");
    meta_friendlyname.setAttribute("name", "q" + form_field_prefix + "[et_meta][friendly_name]");
    meta_friendlyname.setAttribute("value", parameters.friendlyName);
    meta_friendlyname.setAttribute("type", "hidden");
    meta_friendlyname.id = form_field_prefix + "[et_meta][friendly_name]";

    form_grp_1.appendChild(meta_friendlyname);
    form_grp_1.appendChild(meta_type);

    form_grp_1.appendChild(hidden);
    form_grp_1.appendChild(input);

    outer_div.appendChild(form_grp_0);
    outer_div.appendChild(form_grp_1);

    return outer_div;
}

function add_filter_boolean_field(element,selector, field_name, outer_div) {
    element.preventDefault();

}

function add_one_exact_one_number(parameters, form_field_prefix, outer_div) {
    var current_condition_number = parseInt(form_field_prefix.substr(-3).replace(/[\[\]']/g,'' ));
    var current_prefix_without_condition = form_field_prefix.substr(0,form_field_prefix.length -3)
    var form_grp_0 = document.createElement("div");
    form_grp_0.className = "form-group col-md-3";

    var form_grp_1 = document.createElement("div");
    form_grp_1.className = "form-group col-md-5";

    var et_metadata = document.createElement("input");
    et_metadata.setAttribute("type", "hidden");
    et_metadata.setAttribute("name","q" + form_field_prefix +"[et_meta]");
    et_metadata.setAttribute("value", "one_exact_one_number");
    et_metadata.id = form_field_prefix + "[et_meta]";

    var and_condition = document.createElement("input");
    and_condition.setAttribute("type", "hidden");
    and_condition.setAttribute("name","q" + form_field_prefix +"[m]");
    and_condition.setAttribute("value", "and");
    and_condition.id = form_field_prefix + "[m][and]";


    var select = document.createElement("select");
    select.setAttribute("name", "q" + form_field_prefix + "[c][0][p]");
    select.className = "form-control";

    var option_eq = document.createElement("option");
    option_eq.setAttribute("value", "eq");
    option_eq.innerText = "Is";

    var option_not_eq = document.createElement("option");
    option_not_eq.setAttribute("value", "not_eq");
    option_not_eq.innerText = "Is Not";

    var option_gt = document.createElement("option");
    option_gt.setAttribute("value", "gt");
    option_gt.innerText = "Is Greater Than";

    var option_lt = document.createElement("option");
    option_lt.setAttribute("value", "lt");
    option_lt.innerText = "Is Less Than";

    var option_does_not_contain = document.createElement("option");
    option_does_not_contain.setAttribute("value", "not_cont");
    option_does_not_contain.innerText = "Does not contain";

    select.appendChild(option_eq);
    select.appendChild(option_not_eq);
    select.appendChild(option_gt);
    select.appendChild(option_lt);

    form_grp_0.appendChild(select);


    var number_input = document.createElement("input");
    number_input.setAttribute("name", "q" + form_field_prefix + "[c][0][v][0][value]");
    number_input.setAttribute("type", "number");
    number_input.setAttribute("step", "1");
    number_input.id = form_field_prefix + "[c][0][v][0][value]";
    number_input.className = "form-control";

    var name_input = document.createElement("input");
    name_input.setAttribute("name", "q" + form_field_prefix + "[c][0][a][0][name]");
    name_input.setAttribute("value", parameters.numberFieldName);
    name_input.setAttribute("type", "hidden");
    name_input.id = form_field_prefix + "[c][0][a][0][name]";

    // This needs fixing.  We don't currently look at the "type" field.  Needs a better solution...
    var type_name_input = document.createElement("input")
    type_name_input.setAttribute("name", "q" + form_field_prefix + "[c][1][v][0][value]");
    type_name_input.setAttribute("value",  parameters.exactFieldValue);
    type_name_input.setAttribute("type", "hidden");
    type_name_input.id = form_field_prefix + "[c][1][v][0][value]";

    var type_value_input = document.createElement("input")
    type_value_input.setAttribute("name", "q" + form_field_prefix + "[c][1][a][0][name]");
    type_value_input.setAttribute("value", parameters.exactFieldName);
    type_value_input.setAttribute("type", "hidden");
    type_value_input.id = form_field_prefix + "[c][1][a][0][name]";

    var type_parameter_input = document.createElement("input");
    type_parameter_input.setAttribute("name", "q" + form_field_prefix + "[c][1][p]");
    type_parameter_input.setAttribute("value", "eq");
    type_parameter_input.setAttribute("type", "hidden");

    var meta_type = document.createElement("input");
    meta_type.setAttribute("name", "q" + form_field_prefix + "[c][0][et_meta][type]");
    meta_type.setAttribute("value", "one_exact_one_number");
    meta_type.setAttribute("type", "hidden");
    meta_type.id = form_field_prefix + "[et_meta][type]";

    var meta_friendlyname = document.createElement("input");
    meta_friendlyname.setAttribute("name", "q" + form_field_prefix + "[c][0][et_meta][friendly_name]");
    meta_friendlyname.setAttribute("value", parameters.friendlyName);
    meta_friendlyname.setAttribute("type", "hidden");
    meta_friendlyname.id = form_field_prefix + "[c][0][et_meta][friendly_name]";

    // form_grp_0.appendChild(parameter_input);
    form_grp_0.appendChild(type_parameter_input);
    form_grp_0.appendChild(et_metadata);
    form_grp_0.appendChild(and_condition);
    form_grp_1.appendChild(number_input);
    form_grp_1.appendChild(name_input);
    form_grp_1.appendChild(meta_type);
    form_grp_1.appendChild(meta_friendlyname);
    form_grp_1.appendChild(type_name_input);
    form_grp_1.appendChild(type_value_input);

    outer_div.appendChild(form_grp_0);
    outer_div.appendChild(form_grp_1);

    return outer_div;
}

function add_filter_state_field(parameters, form_field_prefix = null, outer_div) {
    var current_condition_number = parseInt(form_field_prefix.substr(-3).replace(/[\[\]']/g,'' ));
    var current_prefix_without_condition = form_field_prefix.substr(0,form_field_prefix.length -3)
    var form_grp_0 = document.createElement("div");
    form_grp_0.className = "form-group col-md-3";

    var form_grp_1 = document.createElement("div");
    form_grp_1.className = "form-group col-md-5";

    var et_metadata = document.createElement("input");
    et_metadata.setAttribute("type", "hidden");
    et_metadata.setAttribute("name","q" + form_field_prefix +"[et_meta]");
    et_metadata.setAttribute("value", "state_field");
    et_metadata.id = form_field_prefix + "[et_meta]";

    var and_condition = document.createElement("input");
    and_condition.setAttribute("type", "hidden");
    and_condition.setAttribute("name","q" + form_field_prefix +"[m]");
    and_condition.setAttribute("value", "and");
    and_condition.id = form_field_prefix + "[m][and]";


    var parameter_input = document.createElement("input");
    parameter_input.setAttribute("name", "q" + form_field_prefix + "[c][0][p]");
    parameter_input.setAttribute("value", "eq");
    parameter_input.setAttribute("type", "hidden");

    var value_input = document.createElement("input");
    value_input.setAttribute("name", "q" + form_field_prefix + "[c][0][v][0][value]");
    value_input.setAttribute("type", "hidden");
    value_input.setAttribute("value", parameters.status);
    value_input.id = form_field_prefix + "[c][0][v][0][value]";

    var name_input = document.createElement("input");
    name_input.setAttribute("name", "q" + form_field_prefix + "[c][0][a][0][name]");
    name_input.setAttribute("value", parameters.fieldName);
    name_input.setAttribute("type", "hidden");
    name_input.id = form_field_prefix + "[c][0][a][0][name]";

    // This needs fixing.  We don't currently look at the "type" field.  Needs a better solution...
    var type_name_input = document.createElement("input")
    type_name_input.setAttribute("name", "q" + form_field_prefix + "[c][1][a][0][name]");
    type_name_input.setAttribute("value", "type");
    type_name_input.setAttribute("type", "hidden");
    type_name_input.id = form_field_prefix + "[c][1][v][0][value]";

    var type_value_input = document.createElement("input")
    type_value_input.setAttribute("name", "q" + form_field_prefix + "[c][1][v][0][value]");
    type_value_input.setAttribute("value", parameters.workflowName);
    type_value_input.setAttribute("type", "hidden");
    type_value_input.id = form_field_prefix + "[c][1][a][0][name]";

    var type_parameter_input = document.createElement("input");
    type_parameter_input.setAttribute("name", "q" + form_field_prefix + "[c][1][p]");
    type_parameter_input.setAttribute("value", "eq");
    type_parameter_input.setAttribute("type", "hidden");

    var meta_type = document.createElement("input");
    meta_type.setAttribute("name", "q" + form_field_prefix + "[c][0][et_meta][type]");
    meta_type.setAttribute("value", "state");
    meta_type.setAttribute("type", "hidden");
    meta_type.id = form_field_prefix + "[et_meta][type]";

    var meta_friendlyname = document.createElement("input");
    meta_friendlyname.setAttribute("name", "q" + form_field_prefix + "[c][0][et_meta][friendly_name]");
    meta_friendlyname.setAttribute("value", parameters.friendlyName);
    meta_friendlyname.setAttribute("type", "hidden");
    meta_friendlyname.id = form_field_prefix + "[c][0][et_meta][friendly_name]";


    // End part that needs looking at..
    form_grp_0.appendChild(parameter_input);
    form_grp_0.appendChild(type_parameter_input);
    form_grp_0.appendChild(et_metadata);
    form_grp_0.appendChild(and_condition);
    form_grp_1.appendChild(name_input);
    form_grp_1.appendChild(meta_type);
    form_grp_1.appendChild(meta_friendlyname);
    form_grp_1.appendChild(value_input);
    form_grp_1.appendChild(type_name_input);
    form_grp_1.appendChild(type_value_input);

    outer_div.appendChild(form_grp_0);
    outer_div.appendChild(form_grp_1);

    return outer_div;
}

function add_filter_association_field(parameters, form_field_prefix = null, outer_div) {
    var form_grp_0 = document.createElement("div");
    form_grp_0.className = "form-group col-md-3";

    var type_parameter_input = document.createElement("input");
    type_parameter_input.setAttribute("name", "q" + form_field_prefix +"[p]");
    type_parameter_input.setAttribute("value", "gteq");
    type_parameter_input.setAttribute("type", "hidden");

    form_grp_0.appendChild(type_parameter_input);

    var form_grp_1 = document.createElement("div");
    form_grp_1.className = "form-group col-md-5";

    var input = document.createElement("input");
    input.setAttribute("name", "q" + form_field_prefix + "[v][0][value]");
    input.setAttribute("value", "1");

    input.setAttribute("type", "hidden");
    input.id = form_field_prefix + "[v][0][value]";
    input.className = "form-control";

    var hidden = document.createElement("input");
    hidden.setAttribute("name", "q" + form_field_prefix + "[a][0][name]");
    hidden.setAttribute("value", parameters.fieldName);
    hidden.setAttribute("type", "hidden");
    hidden.id = form_field_prefix + "[v][0][value]";

    var meta_type = document.createElement("input");
    meta_type.setAttribute("name", "q" + form_field_prefix + "[et_meta][type]");
    meta_type.setAttribute("value", "association");
    meta_type.setAttribute("type", "hidden");
    meta_type.id = form_field_prefix + "[et_meta][type]";

    var meta_friendlyname = document.createElement("input");
    meta_friendlyname.setAttribute("name", "q" + form_field_prefix + "[et_meta][friendly_name]");
    meta_friendlyname.setAttribute("value", parameters.friendlyName);
    meta_friendlyname.setAttribute("type", "hidden");
    meta_friendlyname.id = form_field_prefix + "[et_meta][friendly_name]";

    form_grp_1.appendChild(meta_friendlyname);
    form_grp_1.appendChild(meta_type);

    form_grp_1.appendChild(hidden);
    form_grp_1.appendChild(input);

    outer_div.appendChild(form_grp_0);
    outer_div.appendChild(form_grp_1);

    return outer_div;
}

function add_filter_exact_field(parameters, form_field_prefix = null, outer_div) {
    var form_grp_0 = document.createElement("div");
    form_grp_0.className = "form-group col-md-3";

    var type_parameter_input = document.createElement("input");
    type_parameter_input.setAttribute("name", "q" + form_field_prefix + "[p]");
    type_parameter_input.setAttribute("value", "eq");
    type_parameter_input.setAttribute("type", "hidden");

    form_grp_0.appendChild(type_parameter_input);

    var form_grp_1 = document.createElement("div");
    form_grp_1.className = "form-group col-md-5";

        var input = document.createElement("input");
        input.setAttribute("name", "q" + form_field_prefix + "[v][0][value]");
        input.setAttribute("value", parameters.fieldValue);
        input.setAttribute("type", "hidden");
        input.id = form_field_prefix + "[v][0][value]";
        input.className = "form-control";

    var hidden = document.createElement("input");
    hidden.setAttribute("name", "q" + form_field_prefix + "[a][0][name]");
    hidden.setAttribute("value", parameters.fieldName);
    hidden.setAttribute("type", "hidden");
    hidden.id = form_field_prefix + "[v][0][value]";

    var meta_type = document.createElement("input");
    meta_type.setAttribute("name", "q" + form_field_prefix + "[et_meta][type]");
    meta_type.setAttribute("value", "exact");
    meta_type.setAttribute("type", "hidden");
    meta_type.id = form_field_prefix + "[et_meta][type]";

    var meta_friendlyname = document.createElement("input");
    meta_friendlyname.setAttribute("name", "q" + form_field_prefix + "[et_meta][friendly_name]");
    meta_friendlyname.setAttribute("value", parameters.friendlyName);
    meta_friendlyname.setAttribute("type", "hidden");
    meta_friendlyname.id = form_field_prefix + "[et_meta][friendly_name]";

    form_grp_1.appendChild(meta_friendlyname);
    form_grp_1.appendChild(meta_type);

    form_grp_1.appendChild(hidden);
    form_grp_1.appendChild(input);

    outer_div.appendChild(form_grp_0);
    outer_div.appendChild(form_grp_1);

    return outer_div;
}

function add_filter_null_field(parameters, form_field_prefix = null, outer_div) {
    var form_grp_0 = document.createElement("div");
    form_grp_0.className = "form-group col-md-3";

    var type_parameter_input = document.createElement("input");
    type_parameter_input.setAttribute("name", "q" + form_field_prefix + "[p]");
    type_parameter_input.setAttribute("value", "blank");
    type_parameter_input.setAttribute("type", "hidden");

    form_grp_0.appendChild(type_parameter_input);

    var form_grp_1 = document.createElement("div");
    form_grp_1.className = "form-group col-md-5";

    var input = document.createElement("input");
    input.setAttribute("name", "q" + form_field_prefix + "[v][0][value]");
    input.setAttribute("value", parameters.fieldValue);
    input.setAttribute("type", "hidden");
    input.id = form_field_prefix + "[v][0][value]";
    input.className = "form-control";

    var hidden = document.createElement("input");
    hidden.setAttribute("name", "q" + form_field_prefix + "[a][0][name]");
    hidden.setAttribute("value", parameters.fieldName);
    hidden.setAttribute("type", "hidden");
    hidden.id = form_field_prefix + "[a][0][name]";

    var meta_type = document.createElement("input");
    meta_type.setAttribute("name", "q" + form_field_prefix + "[et_meta][type]");
    meta_type.setAttribute("value", "null");
    meta_type.setAttribute("type", "hidden");
    meta_type.id = form_field_prefix + "[et_meta][type]";

    var meta_friendlyname = document.createElement("input");
    meta_friendlyname.setAttribute("name", "q" + form_field_prefix + "[et_meta][friendly_name]");
    meta_friendlyname.setAttribute("value", parameters.friendlyName);
    meta_friendlyname.setAttribute("type", "hidden");
    meta_friendlyname.id = form_field_prefix + "[et_meta][friendly_name]";

    form_grp_1.appendChild(meta_friendlyname);
    form_grp_1.appendChild(meta_type);

    form_grp_1.appendChild(hidden);
    form_grp_1.appendChild(input);

    outer_div.appendChild(form_grp_0);
    outer_div.appendChild(form_grp_1);

    return outer_div;
}

function add_filter_noteq_field(parameters, form_field_prefix = null, outer_div) {
    var form_grp_0 = document.createElement("div");
    form_grp_0.className = "form-group col-md-3";

    var type_parameter_input = document.createElement("input");
    type_parameter_input.setAttribute("name", "q" + form_field_prefix + "[p]");
    type_parameter_input.setAttribute("value", "not_eq");
    type_parameter_input.setAttribute("type", "hidden");

    form_grp_0.appendChild(type_parameter_input);

    var form_grp_1 = document.createElement("div");
    form_grp_1.className = "form-group col-md-5";

    var input = document.createElement("input");
    input.setAttribute("name", "q" + form_field_prefix + "[v][0][value]");
    input.setAttribute("value", parameters.fieldValue);
    input.setAttribute("type", "hidden");
    input.id = form_field_prefix + "[v][0][value]";
    input.className = "form-control";

    var hidden = document.createElement("input");
    hidden.setAttribute("name", "q" + form_field_prefix + "[a][0][name]");
    hidden.setAttribute("value", parameters.fieldName);
    hidden.setAttribute("type", "hidden");
    hidden.id = form_field_prefix + "[a][0][name]";

    var meta_type = document.createElement("input");
    meta_type.setAttribute("name", "q" + form_field_prefix + "[et_meta][type]");
    meta_type.setAttribute("value", "noteq");
    meta_type.setAttribute("type", "hidden");
    meta_type.id = form_field_prefix + "[et_meta][type]";

    var meta_friendlyname = document.createElement("input");
    meta_friendlyname.setAttribute("name", "q" + form_field_prefix + "[et_meta][friendly_name]");
    meta_friendlyname.setAttribute("value", parameters.friendlyName);
    meta_friendlyname.setAttribute("type", "hidden");
    meta_friendlyname.id = form_field_prefix + "[et_meta][friendly_name]";

    form_grp_1.appendChild(meta_friendlyname);
    form_grp_1.appendChild(meta_type);

    form_grp_1.appendChild(hidden);
    form_grp_1.appendChild(input);

    outer_div.appendChild(form_grp_0);
    outer_div.appendChild(form_grp_1);

    return outer_div;
}
window.et_convertSimpleFilterToAdvanced = et_convertSimpleFilterToAdvanced;
window.et_buildSimpleFilter = et_buildSimpleFilter;
window.create_condition = create_condition;
window.remove_condition = remove_condition;
window.buildConditionSet = buildConditionSet;
window.deleteConditionSet = deleteConditionSet;
window.et_filter_calculate_depth = et_filter_calculate_depth;
