import { Controller } from 'stimulus'

export default class extends Controller {
    static values = {
        refreshIntervalMs: Number,
        warningSeconds: Number,
        logoutSeconds: Number,
    }

    connect() {
        this.loginAttempts = 0;
        if(this.hasRefreshIntervalMsValue) {
            // connectedTimer is a lock to make sure we don't try to ping
            // whilst there is still a timer running in the background.
            this.connectedTimer = true;
            this.startRefreshing();
        }
    }

    disconnect() {
        this.stopRefreshing();
    }


    /**
     * startRefreshing launches a timer which is responsible for the process of connecting to the /ping api service
     * The ping api reports the time left before the user will be logged out, and so out inner function determines
     * when the right time is to load the page modals.
     *
     * @return {[void]} Starts a countdown timer.
     */
    startRefreshing() {
        this.refreshTimer = setInterval(async () => {
            // Lock here.
            if(this.connectedTimer == true) {
                var timeLeft = await this.getSessionExpiryTime();
                var sessionWarningModal = document.getElementById("sessionWarningModal");

                if (timeLeft <= this.warningSecondsValue && timeLeft >= this.logoutSecondsValue) {
                    // Pop up the warning modal form
                    if (!sessionWarningModal.classList.contains("show") && sessionWarningModal.getAttribute("data-displayed") != "true") {
                        // Must use JQuery here since we're using Bootstrap V4.  In Bootstrap v5, we can
                        // use vanilla javascript to load the modal.
                        $("#sessionWarningModal").modal({backdrop: 'static', keyboard: false});
                        // set the flag on the modal so that we know we've already been displayed.
                        // given that, we can make sure we don't display the modal again if it is dismissed by
                        // the user
                        sessionWarningModal.setAttribute("data-displayed", "true");
                        this.startCountdown(timeLeft, this.logoutSecondsValue);
                    }
                }
                if (timeLeft <= this.warningSecondsValue && timeLeft <= this.logoutSecondsValue) {
                    // remove the warning modal
                    $("#sessionWarningModal").modal("hide");
                    // show the login modal
                    $("#loginModal").modal({backdrop: 'static', keyboard: false});
                    $("#idle-password-field").focus()
                    // Clear the interval timer.
                    clearInterval(this.refreshTimer)
                }
            } else {
                // If we're locked, then remove the timer anyway.
                clearInterval(this.refreshTimer)
            }
            // unlock
        }, this.refreshIntervalMsValue)
    }

    /**
     * stopRefreshing will stop the page interval timer from connecting to the /ping service
     *
     * @return {[void]} stops the timer..
     */
    stopRefreshing() {
        if (this.refreshTimer) {
            clearInterval(this.refeshTimer)
        }
        this.connectedTimer = false
    }

    /**
     * startCountdown will cause the text of the element "sessionWarningCountdown" to countdown from
     * current time, to logout time.  I.e. This will present to the user how much time is remaining before
     * they are due to be logged out.
     *
     * @param  {[int]} currentTime [The the the user has left as reported by the API ]
     * @param  {[int]} logoutTime [The time we present the password box to the user.]
     * @return {[void]} Starts a countdown timer.
     */
    startCountdown(currentTime, logoutTime) {
        var sessionWarningCountdown = document.getElementById("sessionWarningCountdown");
        sessionWarningCountdown.innerHTML = Math.floor(currentTime - logoutTime)
        var countdown = setInterval( () => {
            var t = parseInt(sessionWarningCountdown.innerHTML);
            t = t - 1
            sessionWarningCountdown.innerHTML = t;
            if(t <= 0) {
                clearInterval(countdown);
            }
        }, 1000)
    }

    /**
     * getSessionExpiryTime connects to the ping api service to report the amount of time left before session expiry.
     *
     * @return {[int]} the amount of time left before session expiry.
     */
    async getSessionExpiryTime() {
        var response = await fetch("/services/ping");
        if(!response.ok) {
            return 0;
        } else {
            var json = await response.json();
            return json.pong
        }
    }

    /**
     * logMeIn starts the login process
     *  - Log out the user
     *  - Log in the user
     *  - if successful then cleanup the modals and DOM data.
     *  - If unsuccessful then present the appropriate error to the user
     *
     * @return {[void]}
     */
    async logMeIn() {
        const MAX_LOGIN_ATTEMPTS = 3;

        if(this.loginAttempts <= MAX_LOGIN_ATTEMPTS) {
            let data = {account: {username: "", password: "", otp_attempt: ""}};
            data.account.username = document.getElementById("idle-username-field").value;
            data.account.password = document.getElementById("idle-password-field").value;
            if(document.getElementById("idle-otp-field")) {
                data.account.otp_attempt = document.getElementById("idle-otp-field").value;
            }

            // Force a logout of the user...
            await fetch('/services/auth/sign_in/2', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Now force a login
            await fetch('/services/auth/sign_in', {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                        // we need to update the crsf token too.
                    } else {
                        this.loginAttempts = this.loginAttempts + 1;
                        if (this.loginAttempts >= MAX_LOGIN_ATTEMPTS) {
                            document.getElementById("idle-password-error-text").innerText = "No more attempts remaining.  Please log out.";
                            document.getElementById("idle-sign-back-in").classList.add("d-none");
                            document.getElementById("idle-password-group").classList.add("d-none");
                            if(document.getElementById("idle-otp-field")) {
                                document.getElementById("idle-otp-field").classList.add("d-none");
                            }
                        } else {
                            document.getElementById("idle-password-field").value = "";
                            if(document.getElementById("idle-otp-field")) {
                                document.getElementById("idle-otp-field").value = "";
                            }
                            var attemptsRemaining = (MAX_LOGIN_ATTEMPTS - this.loginAttempts);
                            document.getElementById("idle-password-error-text").innerText = "Incorrect Password. " + attemptsRemaining + " attempts remaining.";
                        }
                    }
                })
                .then(data => {
                    if(data!=undefined) {
                        // Update all the CSRF tokens on the current page
                        // the meta token needs to be updated
                        document.head.children.namedItem('csrf-token').content = data.csrf_meta;

                        // then we need to update all the forms with the correct authenticity token.
                        document.getElementsByName("authenticity_token").forEach((e) => {
                            e.value = data.csrf;
                        })

                        // Hide the login modal
                        $("#loginModal").modal("hide");

                        // Reset the values
                        document.getElementById("idle-password-field").value = "";
                        sessionWarningModal.setAttribute("data-displayed", "false");
                        document.getElementById("idle-password-error-text").innerText = "";
                        if(document.getElementById("idle-otp-field")) {
                            document.getElementById("idle-otp-field").value = "";
                        }
                        this.loginAttempts = 0;

                        // Start the countdown again
                        this.startRefreshing();
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    /**
     * passwordEnter is used by the password field to press the enter key to submit.
     *
     * @param  {[event]} event
     * @return {[void]}
     */
    passwordEnter(event) {
        if(event.keyCode == 13) {
            this.logMeIn();
        }
    }
}