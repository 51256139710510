import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
      url: String,
      greenMax: Number,
      amberMax: Number,
      linkUrl: String
  }
  static targets = [ "count" ]

  connect() {
     this.load();
  }

  load() {
    var countTarget = this.countTarget;

    fetch(this.urlValue)
      .then(response => {
        if(response.status === 200) {
              return response.json();
        } else {
            throw `error with status ${response.status}`;
        }
        })
        .then(json => {
            var colour = "danger"
            if(this.greenMaxValue && this.amberMaxValue) {
                if(parseInt(json.total) <= this.greenMaxValue) {
                    colour = "success";
                } else {
                    if(parseInt(json.total) <= this.amberMaxValue) {
                        colour = "warning"
                    }
                }
            }
            countTarget.innerHTML = "";
            countTarget.innerHTML = "<div style='width: 200px;'><a href='" +this.linkUrlValue + "' target='_blank' class='btn btn-block btn-" + colour +"'>" + json.total + "</a></div>"
        })
        .catch(err => {
            countTarget.innerHTML = "";
            countTarget.innerHTML = "<div class='valign-icon-text-div'><i class='fa fa-2x text-danger fa-times-circle pr-1'> </i> An Error Occurred</div>"
        })
  }
}