import { Controller } from 'stimulus'

export default class extends Controller {
    static values = {
        modelId: Number,
        path: String,
    }

    delete(event) {
        event.preventDefault()
        var token = document.head.querySelector('meta[name="csrf-token"]').content
        var element = document.body.querySelector('tr[data-model-id="' +  this.modelIdValue +'"]')
        var total_records_element = document.getElementById("total_records");

        if (window.confirm("Are you sure you wish to delete this?")) {
            fetch(this.pathValue + ".json", {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ authenticity_token: token })
            }).then(response => {
                if (response.status === 200 || response.status == 302 ) {
                    element.classList.add("d-none");
                     if(total_records_element) {
                         var total_records_left = parseInt(total_records_element.dataset.count) -1
                         total_records_element.dataset.count = total_records_left
                         if(total_records_left == 0) {
                             total_records_element.innerText = "0 Records Found"
                         } else {
                             if(total_records_left == 1) {
                                 total_records_element.innerText = "1 Record Found"
                             } else {
                                 total_records_element.innerText = "" + total_records_left + " Records Found"
                             }
                         }
                     }
                } else {
                    alert("There was an error deleting the item you specified")
                }
            });
        }
    }

    void(event) {
        event.preventDefault()
        var token = document.head.querySelector('meta[name="csrf-token"]').content
        var element = document.body.querySelector('tr[data-model-id="' +  this.modelIdValue +'"]')
        var action_button = document.getElementById(this.modelIdValue + "_action_button");

        if (window.confirm("Are you sure you wish to delete this?")) {
            fetch(this.pathValue + ".json", {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ authenticity_token: token })
            }).then(response => {
                if (response.status === 200 || response.status == 302 ) {
                    element.classList.add("text-muted");
                    if(action_button) {
                        action_button.classList.add("d-none");
                    }
                } else {
                    alert("There was an error deleting the item you specified")
                }
            });
        }
    }

}