import { Controller } from "stimulus"

const LEAVING_MESSAGE = "Warning: You have unsaved changes on this page.  If you leave, those changes will be lost.  Are you sure you want to leave?"

export default class extends Controller {

    formIsChanged(event) {
        this.setChanged("true");
    }

    setChanged(changed) {
        this.data.set("changed", changed)
    }

    allowFormSubmission(event) {
        var invalidElements = document.getElementsByClassName("is-invalid")
        if((invalidElements.length) == 0)
        {
            this.setChanged("false");
        }
    }

    isFormChaged() {
        return this.data.get("changed") === "true";
    }

    leavingPage(event) {
        if(this.isFormChaged()) {
            if(event.type == "turbolinks:before-visit") {
                if(!window.confirm(LEAVING_MESSAGE)) {
                    event.preventDefault();
                }
            } else {
                event.returnValue = LEAVING_MESSAGE;
                return event.returnValue;
            }
        }
    }
}